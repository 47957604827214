import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CallserviceService } from 'src/app/CallServices/callservice.service';
import { TokenEncryptionService } from 'src/app/CallServices/token-encryption.service';
import {
  Http,
  RequestOptions,
  URLSearchParams,
  HttpModule,
  Headers,
  ResponseContentType,
} from "@angular/http";
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class UserPreferencesServiceService {
  private readonly DEFAULT_DASHBOARD_KEY = 'defaultDashboard';
  usertoken = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("usertoken"))  //Decryption sessionStorage.getItem("usertoken");
  
 
   
  currentUser = this.tokenEncryptDecrypt.decryptToken(localStorage.getItem("currentUser"));//localStorage.getItem('currentUser');
  Currentdataset = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("currentDataset"));
  currentEnvironment = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("currentEnvironment"));
  userID = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("userid"));
  username = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("username"));
  usertype = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("usertype"));
  functionnm = "getUserDashboardConfig";
  private headers = { headers: new Headers({ Authorization: this.tokenEncryptDecrypt.decryptToken(this.usertoken) }) };

  constructor(private tokenEncryptDecrypt: TokenEncryptionService, private api: CallserviceService,
    private http: HttpClient) { }

 

  //  getUserDashboardConfig1() {
  //   //console.log('currentEnvironment' , this.currentEnvironment)
  //   const body = { envname:  this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("currentEnvironment")) }; // Body formatted as JSON object
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': "Bearer " + this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("usertoken"))
  //   });

  //   // Call with JSON body and headers
  //   return this.http.post(
  //     `${environment.RestService_rcm}${this.functionnm}`,
  //     body,
  //     { headers: headers }
  //   );
  // }



  

 private userDashboardConfig$: Observable<any> | null = null; // Cache variable
 private cachedConfig: any | null = null; // Store actual response
  

  getUserDashboardConfig(): Observable<any> {
    // If we already have a cached response, return it as an observable
    if (this.cachedConfig) {
      return of(this.cachedConfig);
    }
    // If the request has not been made yet, make it and cache the result
    if (!this.userDashboardConfig$) {
      const body = { envname: this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("currentEnvironment"))};
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("usertoken") || '')
      });
      // Store the observable response with caching
      this.userDashboardConfig$ = this.http.post(
        `${environment.RestService_rcm}${this.functionnm}`,
        body,
        { headers: headers }
      ).pipe(
        tap(response => this.cachedConfig = response), // Save response after first request
        shareReplay(1)  // Cache observable 
        //When applied to an observable, shareReplay ensures that once a value is fetched (e.g., from an API), it’s stored,
        // and subsequent subscribers receive the cached result without triggering a new request.
      );
    }
    return this.userDashboardConfig$;
  }






}
