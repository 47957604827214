import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { GroupDescriptor, State, DataResult, process } from "@progress/kendo-data-query";
import { AuditLogService } from 'src/app/CallServices/audit-log.service';
import { event } from 'jquery';
// import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
@Component({
  selector: 'app-basic-color-table',
  templateUrl: './basic-color-table.component.html',
  styleUrls: ['./basic-color-table.component.css']
})
export class BasicColorTableComponent implements OnInit, OnChanges {
  @Input() dataSource: any;
  @Input() disabled: any;
  @Input() metaData: any;
  @Input() cardName: any;
  @Input() Exportfilename: any;
  @Input() exportSource: any;
  @Input() selectedBucket: any;
  @Input() SelectedRecord: any;
  @Input() Height: any;
  @Input() bIsLoader : any;
  @Output() cellClick = new EventEmitter<any>();
  @Output() explorerClick = new EventEmitter<any>();
  @Output() AmountEnter = new EventEmitter<any>();
  @Output() exportClick = new EventEmitter<any>();
  @Output() BucketSelect = new EventEmitter<any>();

  gridData;
  Limitvalue: any;
  field: any;
  title: any;
  i: any;
  public isSelectedRow: any;
  column1value: any;
  title1: any;
  title2: any;
  index: any
  percentColors: any;
  isExploreHidden: string;
  exportData: any;
  colorcode: any;
  exportButton:any;
  height:any;

  selectbucketList = ["By Plan", "By Company","By Practice"];
  Selectedrecordlimit = [ "Top 10", "Top 20", "Top 50"];
  Limit: any;
  pointer: any;
  title3: any;
  public groups: GroupDescriptor[] = [
  ];
  column3: boolean;
  InsurancedDropdown: any;
  Headershide: any;
  exportdisabled: any;
  exportdisabled1: any;
  constructor(
    public logapi: AuditLogService
    // private sanitizer: DomSanitizer
  ) { this.ByPayerExportData = this.ByPayerExportData.bind(this); }

  ngOnInit(): void {
    this.SelectedRecord = "Top 20" ;
    this.selectedBucket = "By Plan";
    this.Limitvalue = "20";
    this.i = 15;
    this.index = 0;
    this.isSelectedRow = "false";
    this.column1value = null;
    this.isExploreHidden = this.metaData[0].exploreButton;
    this.colorcode ="false";
    this.colorcode = this.metaData[0].color;
    this.pointer = this.metaData[0].pointer;
  }

  ngOnChanges() {
    //;
    let name = this.metaData[0].name;
    name = name.replace(/['"]+/g, '');
    //console.log("name",name);
    this.gridData = this.dataSource;
    this.exportdisabled =this.disabled
    this.exportdisabled1 =this.disabled
    this.exportData = this.exportSource;
  
    this.column1value = null;
    this.title1 = this.metaData[0].title;
    this.title2 = this.metaData[1].title;
    if(this.metaData.length>2){
    this.title3 = this.metaData[2].title;
    }
    //this.Height = this.metaData[0].height;
    this.Limit =this.metaData[0].limit;
    this.InsurancedDropdown =this.metaData[0].Insdropdown;
    this.Headershide =this.metaData[0].Headers;
    this.exportButton = this.metaData[0].exportButton;
    //debugger;
    if (!isNullOrUndefined(this.gridData)) {
      if (this.gridData.length > 0) {  
       //debugger;
        for (let i = 0; i < this.gridData.length; i++) {
         // this.Height= this.gridData[i][this.metaData[0].height];
          if(this.metaData.length>2){
          this.gridData[i].column1 = this.gridData[i][this.metaData[0].name];
          this.gridData[i].column2 = this.gridData[i][this.metaData[1].name];
          this.gridData[i].column3 = this.gridData[i][this.metaData[2].name];
          this.column3=false;
          }else{
            this.column3=true;
            this.gridData[i].column1 = this.gridData[i][this.metaData[0].name];
            this.gridData[i].column2 = this.gridData[i][this.metaData[1].name];
          }
        }
        // for (let i = 0; i < this.gridData.length; i++) {
        //  debugger;
        //   this.Height= this.gridData[i][this.metaData[0].height];
        //   this.gridData[i].column1 = this.gridData[i][this.metaData[0].name];
        //   // this.gridData[i].order1 = this.metaData[0].order;
        //   // this.gridData[i].title1 = this.metaData[0].title;
        //   this.gridData[i].column2 = this.gridData[i][this.metaData[1].name];
        //   this.gridData[i].column3 = this.gridData[i][this.metaData[2].name];
        //   // this.gridData[i].order2 = this.metaData[1].order;
        //   // this.gridData[i].title2 = this.metaData[1].title;

        // }
      }
    }
    if (!isNullOrUndefined(this.exportData)) {     
        for (let i = 0; i < this.exportData.length; i++) {
          if (this.exportData.length > 0) {
            if(this.metaData.length>2){
              this.exportData[i].column1 = this.exportData[i][this.metaData[0].name];
              this.exportData[i].column2 = this.exportData[i][this.metaData[1].name];
              this.exportData[i].column3 = this.exportData[i][this.metaData[2].name];
              this.column3=false;
              }else{
                this.column3=true;
                this.exportData[i].column1 = this.exportData[i][this.metaData[0].name];
                this.exportData[i].column2 = this.exportData[i][this.metaData[1].name];
              }
          }
         
        }   
    }
  }




  public colorCode(code: any) {
    if (this.colorcode == "true") {
      let result;
      ;
      if (code == 0) {
        this.i = 15;
      }
      result = this.dataSource.length - 1;
      if (code < this.dataSource.length) {
        let color = "3366cc"
        let amount = this.i;
        let rgb = '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
        this.i = this.i + 15;
        return rgb
      } else {
        return;
      }
    }
  }

  public removeHandler({ dataItem }) {

  }

  explorer(evet) {
    this.explorerClick.emit(evet);
  }

  CellClick(event) {
    if (this.column1value == event.column1) {
      this.column1value = null;
      this.isSelectedRow = "false";

    } else {
      this.column1value = event.column1;
      this.isSelectedRow = "true";
    }
    this.cellClick.emit(event);

  }



  public checkNegative(dataitem: any) {
    if (!isNullOrUndefined(dataitem)) {
      if (dataitem.toString().indexOf("-") == -1) {
        return true;
      }
    }
  }


  ByPayerExportData(): ExcelExportData {
    this.logapi.insertActivityLog(
      "By Payer Excel Downloaded",
      "Denials",
      "Download"
    );

    const result: ExcelExportData = {
      data: process(this.exportData, {
        sort: [{ field: "column2", dir: "desc" }],
      }).data,
    };
    return result;
  }

  ExportDataClick(){
    this.exportClick.emit();
  }

  PayerRecordsby() {
    if (this.SelectedRecord == "Top 10") {
      this.Limitvalue = "10";
    //this.Height = "300";
    }
    if (this.SelectedRecord == "Top 20") {
      this.Limitvalue = "20";
      // this.Height = "300";
      
    }
    if (this.SelectedRecord == "Top 50") {
      this.Limitvalue = "50";
      // this.Height = "565";
     
    }
    
      this.AmountEnter.emit(this.Limitvalue);
          
  }


  bucketValueChange() {
    this.BucketSelect.emit(this.selectedBucket) ;     
  }

}

function isNullOrUndefined(value: any): boolean { return value === null || value === undefined; }
